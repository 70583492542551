import React from 'react';

function VideoComponent() {
    return (
        <div className="video-container">
            <h3>For every 1 shard in your wallet you will have 100 additional land claim blocks</h3>
            <video autoPlay loop muted>
                <source src="./preview.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default VideoComponent;