import React from 'react';
import WaxWalletLoginComponent from './WaxWalletLoginComponent';

import ServerStatusComponent from './ServerStatusComponent';
import WhitelistComponent from './WhitelistComponent';
import VideoComponent from './VideoComponent'; // Import the VideoComponent


function HomePage() {
    return (
        <div>
            <WaxWalletLoginComponent />

            <ServerStatusComponent />
            <WhitelistComponent />
            <VideoComponent />
        </div>
    );
}

export default HomePage;