import React, { useState, useEffect } from 'react';
import axios from 'axios';

function WhitelistComponent() {
    const [whitelistData, setWhitelistData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('/api/json-data');
            //console.log(response);
            const jsonData = await response.json();
            setWhitelistData(jsonData);
            //console.log('Fetched JSON Data:', jsonData);
        } catch (error) {
            //console.error('Error fetching JSON data:', error);
            setError(error.message);
        }
    };

    return (
        <div className="whiteListedPlayers">
            <h3>Whitelisted Players</h3>
            <h4>(refreshes every 30 minutes)</h4>
            {error ? (
                <p className="error">{error}</p>
            ) : whitelistData ? (
                <div>
                    <ul>
                        {whitelistData.players.map((player, index) => (
                            <li key={index}>{player}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default WhitelistComponent;