import React, { useState, useEffect } from 'react';
import { SessionKit, Chains, Session } from '@wharfkit/session';


import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import axios from 'axios';
import { Buffer } from 'buffer'; // Import the Buffer polyfill
import MinecraftIdComponent from './MinecraftIdComponent';
// Polyfill the global Buffer object
window.Buffer = Buffer;


function WaxWalletLoginComponent() {
    const webRenderer = new WebRenderer();
    const chains = [Chains.WAX];
    const anchor = new WalletPluginAnchor();
    const wcw = new WalletPluginCloudWallet();
    const sessionKit = new SessionKit({
        appName: 'moddedcraft',
        chains,
        ui: webRenderer,
        walletPlugins: [anchor, wcw] // Only using WalletPluginAnchor for SessionKit
    });

    const [user, setUser] = useState(null);
    const [csrfToken, setCsrfToken] = useState('');
    const [minecraftName, setMinecraftName] = useState('');
    const [minecraftUUID, setMinecraftUUID] = useState('');
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch CSRF token once
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get('/api/csrf-token');
                setCsrfToken(response.data.csrfToken);
                // Restore session after fetching CSRF token
                restoreSession(response.data.csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();

        // Restore session if available
        const restoreSession = async (csrfToken) => {
            try {
                const rsessions = await sessionKit.getSessions();
                //console.log('Restored Sessions:', rsessions);

                // Use some form of logic or allow the user to pick one
                // In this simple example, we just select the first in the array
                const selected = rsessions[0];

                // Pass the selected session to the restore call
                const session = await sessionKit.restore(selected);

                //console.log('Restored session:', session);
                if (session) {
                    const waxAddress = session.actor.toString();
                    //console.log('Restored Session Actor:', waxAddress);
                    setUser(waxAddress);
                    // Fetch user data from the server
                    const response = await axios.get(`/api/get-user?wax_address=${waxAddress}`, {
                        headers: {
                            'CSRF-Token': csrfToken
                        }
                    });
                    setUserData(response.data);
                }
            } catch (error) {
                console.error('Error restoring session:', error);
            }
        };
    }, []);


    const login = async () => {

        try {

            //console.log('Logging in with Anchor...');
            const session = await sessionKit.login();

            console.log('Session:', session);
            console.log('Session Actor:', session.session.actor.toString());
            console.log('Session Permissions:', session.session.permissionLevel.permission.toString());
            const waxAddress = session.session.actor.toString();
            setUser(waxAddress);
            //console.log('Login Success:', waxAddress);
            // Store user data in local storage
            localStorage.setItem('waxUser', JSON.stringify(waxAddress));
            // Send WAX address to the server
            axios.post('/api/store-user', {
                wax_address: waxAddress
            }, {
                headers: {
                    'CSRF-Token': csrfToken
                }
            })
                .then(response => {
                    console.log('User data stored:', response.data);
                    setUserData(response.data);
                })
                .catch(error => {
                    console.error('Error storing user data:', error);
                });
        } catch (error) {
            console.error('Login Failed:', error);
        }
    };


    const logOut = () => {
        setUser(null);
        setUserData(null);
        // Clear user data from local storage
        localStorage.removeItem('waxUser');
        sessionKit.logout()
        window.location.reload();
    };

    const updateMinecraftInfo = async () => {
        setError('');
        try {
            // Fetch player's UUID from the proxy endpoint
            const uuidResponse = await axios.get(`/api/minecraft-uuid/${minecraftName}`);
            const { id: minecraftId } = uuidResponse.data;
            setMinecraftUUID(minecraftId);
            //console.log('Minecraft ID:', minecraftId);
            // Send Minecraft name and UUID to the server
            axios.post('/api/update-minecraft-info', {
                wax_address: user,
                minecraft_name: minecraftName,
                minecraft_uuid: minecraftId
            }, {
                headers: {
                    'CSRF-Token': csrfToken
                }
            })
                .then(response => {
                    console.log('Minecraft info updated:', response.data);
                    setUserData(response.data);
                })
                .catch(error => {
                    console.error('Error updating Minecraft info:', error);
                    if (error.response && error.response.data.error) {
                        setError(error.response.data.error);
                    } else {
                        setError('Error updating Minecraft info.');
                    }
                });
        } catch (error) {
            console.error('Error fetching Minecraft ID:', error);
            setError('Error fetching Minecraft ID.');
        }
    };

    return (
        <div>
            {user ? (
                <div>
                    <h3>User Logged in</h3>
                    <p>WAX Account: {user}</p>
                    {userData ? (
                        <div>
                            {userData.minecraft_name && userData.minecraft_name !== '' ? (
                                <p>Minecraft Name: {userData.minecraft_name}</p>
                            ) : (
                                <p>Link your Minecraft Account to be whitelisted</p>
                            )}
                            {userData.minecraft_uuid && (
                                <p>Minecraft UUID: {userData.minecraft_uuid}</p>
                            )}
                        </div>
                    ) : (
                        <p>Loading user data...</p>
                    )}
                    <br />

                    <button onClick={logOut}>Log out</button>
                    <br />
                    <input
                        type="text"
                        value={minecraftName}
                        onChange={(e) => setMinecraftName(e.target.value)}
                        placeholder="Enter Minecraft name"
                    />
                    <button onClick={updateMinecraftInfo}>Update Minecraft Info</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            ) : (
                <div>
                    <h4>Sign in to get whitelisted on play.moddedcraft.com</h4>
                    <div className='loginButtonContainer'>
                        <button onClick={login}>Sign in with WAX Wallet 🚀</button>
                    </div>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            )}
        </div>
    );
}

export default WaxWalletLoginComponent;